import {format} from "date-fns";
import React from "react";
import moment from 'moment';
import 'moment-timezone';
import {isNumber} from "chart.js/helpers";
import {extractImeiFromObject} from "../http/pages/dashboard/sidebar/tree/services/function";

export const getEnv = (name) => {
    return process.env[name];
}

export const isArray = (value) => typeof value === "object"

export const empty = (data) => {

    if (data === null) {
        data = {};
    }

    if (typeof data != 'object' && typeof data !== 'array') {
        data = {};
    }

    let length;
    if (typeof data == 'object') {
        length = Object.keys(data).length ? true : false;
        return !length;
    } else if(typeof data == 'array') {
        length = data.keys().length ? true : false;
        return !length;
    } else if (!data) {
        return true
    }

    return false;
}

export const countArr = (array) => {
    if (typeof array != 'object' && typeof array != 'array' || array === null) {
        array = {};
    }

    let length = 0;
    if (typeof array == 'object') {
        length = Object.keys(array).length;
    } else if(typeof array == 'array') {
        length = array.keys().length;
    }

    return length;
}

export const spaceBetweenProps = () => {
    return {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };
};

export const flexStartProps = () => {
    return {
        display: "flex",
        justifyContent: "flex-start"
    };
};

export const groupByValue = (array) => {
    return Object.values(array).filter((x, i, a) => a.indexOf(x) == i);
}

export function trimLeft(str, charlist) {
    if (charlist === undefined)
        charlist = "/\\/$/";

    return str.replace(new RegExp("^[" + charlist + "]+"), "");
}

export function trimRight(str, charlist) {
    if (charlist === undefined)
        charlist = "/\\/$/";

    return str.replace(new RegExp("[" + charlist + "]+$"), "");
}

export function trimPath(str, charlist) {
    return trimRight(trimLeft(str, charlist), charlist);
}

export const inArray = (index, arr) => {

    if (index === null || arr == undefined) {
        return false;
    }

    return Object.entries(arr).filter((v) => {
        return index === v[1];
    }).length ? true : false;
}

export const removeItemArrBySlug = (clients, slug, key = 'id') => {
    const index = clients.map(item => item[key]).indexOf(slug);
    return [...clients.slice(0, index), ...clients.slice(index+1)];
}

export const ifScrolledToBottom = (pxFromBottom = 0) => {
    const innerHeight  = window.innerHeight;
    const scrollTop    = document.documentElement.scrollTop;
    const scrollHeight = document.scrollingElement.scrollHeight;

    return innerHeight + scrollTop >= scrollHeight - pxFromBottom;
}

export const _today = (f = 'yyyy-MM-dd') => {
    return format(new Date(), f);
}

export const dateFormat = (date = null, f = "YYYY-MM-DD") => {
    return moment(date).format(f);
}

function trimTrailingZeroes(nr) {
    let num = String(nr).replace('.00', '');
    num = (num.indexOf('.') !== -1) ? parseFloat(num) : num;
    return num;
}

export const number_format = (e,n,t,i) => {e=(e+"").replace(/[^0-9+\-Ee.]/g,"");var r=isFinite(+e)?+e:0,a=isFinite(+n)?Math.abs(n):0,o="undefined"==typeof i?",":i,d="undefined"==typeof t?".":t,u="",f=function(e,n){var t=Math.pow(10,n);return""+(Math.round(e*t)/t).toFixed(n)};return u=(a?f(r,a):""+Math.round(r)).split("."),u[0].length>3&&(u[0]=u[0].replace(/\B(?=(?:\d{3})+(?!\d))/g,o)),(u[1]||"").length<a&&(u[1]=u[1]||"",u[1]+=new Array(a-u[1].length+1).join("0")),u.join(d)}

export const priceString = (price, decimals = 2, format = false) => {
    if (!price) {
        return 0;
    }

    // if (format) {
    //     return new Intl.NumberFormat('en-EN', {maximumFractionDigits: decimals})
    //         .format(price)
    // }

    return new Intl.NumberFormat('ru-RU', {maximumFractionDigits: decimals})
        .format(price)
        .replace(',', '.')
}

export const numberFormat = (nr, decimals = 2) => {
    return new Intl.NumberFormat('ru-RU', {maximumFractionDigits: decimals})
        .format(nr)
        .replace(',', '.')
}

export const arrIntersect = (arr1, arr2) => {
    return arr1.filter((n) => arr2.filter((n2) => JSON.stringify(n2) === JSON.stringify(n)).length > 0);
}

export const pluck = (array, key) => {
    const newArr = [];
    objVal(array).map((value) => {
        if (key in value) {
            const keyVal = value[key]
            newArr.push(isNumber(keyVal) ? parseFloat(keyVal) : keyVal);
        }
    })

    return objVal(newArr.filter((v, i, a) => a.indexOf(v) === i))
}

export const arrUnique = (array) => {
    return objVal(array).filter((value, index, self) => {
        return self.indexOf(value) === index
    })
}

export const keyBy = (array, key) => {
    const newArr = [];
    Object.values(array).map((value) => {
        newArr[value[key]] = value;
    })

    return newArr
}

export const groupBy = (arr, field) => {

    const groups = Object.create(null);

    arr.forEach(function (o) {
        let key = ' ' + o[field];
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(o);
    });
    return groups
}

export function fileDownloader(data, filename, mime, bom) {
    var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
    var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200)
    }
}

export const tmstmp = () => {
    return Date.now();
}

export const sortBy = (arr, key, type = 'asc') => {
    const sortedItems = arr.sort(function (a, b) {

        if (type === 'desc') {
            return parseFloat(b[key]) - parseFloat(a[key])
        }

        if (parseFloat(a[key]) > parseFloat(b[key])) {
            return 1;
        }

        if (parseFloat(a[key]) < parseFloat(b[key])) {
            return -1;
        }

        return 0;
    });

    return objVal(sortedItems);
}

export const sortByDesc = (arr, key) => {
    const sortedItems = objVal(arr).sort(function (a, b) {
        if (a[key] > b[key]) {
            return -1;
        }

        if (a[key] < b[key]) {
            return 1;
        }

        return 0;
    });

    return objVal(sortedItems);
};

export const existByKeyVal = (arr, field, val) => {
    return objVal(arr).filter((item) => item?.[field] === val).length > 0;
};

export const objVal = (values) => {
    return Object.values(values)
}

export const objKeys = (values) => {
    return Object.keys(values)
}

export const sum = (arr) => arr.reduce((partialSum, a) => partialSum + a, 0);

export const getObjByField = (arr, field, val) => {
    const index = arr.findIndex((i) => i?.[field] == val )
    return arr?.[index]
}

export const getObjValById = (arr, slug, field) => {
    const data = objVal(arr)
    const index = data.findIndex(({id}) => parseInt(id) == parseInt(slug) )
    return data[index]?.[field]
}

export const monthName = (month) => {
    const date      = new Date(`${month}-01`);
    return date.toLocaleString('en', { month: 'long' });
}

export const redirectToLogin = () => {
    window.location.href = getEnv('REACT_APP_API_HOSTNAME');
}

export const windowReload = () => {
    window.location.reload();
}

export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export const sumByKey = (arr, key) => {
    return objVal(arr).reduce((accumulator, current) => accumulator + Number(current[key]), 0)
}

export const isChildNull = (children) => {
    return React.Children.toArray(children).length > 0
}

export const convertToLocalTimezone = (time) => {
    const moscow = moment.tz(time, "Europe/Moscow");
    const toCurrentTimezone = moscow.clone().tz(moment.tz.guess());
    return moment(toCurrentTimezone.format());
}

export const timeDigit = (val) => {
    if (!val) {
        return '00';
    } else if (val < 10) {
        return `0${val}`;
    }

    return val;
}

export const imeisNumbersArr = ({imeis, ...props}) => {
    if (!empty(imeis)) return imeis;
    return extractImeiFromObject(props);
}

export const int = (n) => parseInt(n);

export const float = (n) => parseFloat(n);

export const isLocal = () => getEnv("REACT_APP_ENV") === "local"

export const isFnc = (fnc) => typeof fnc === "function"

export const removeSteps = (steps, field) => {
    const remove = []
    const index = steps.findIndex(({name}) => name === field)
    steps.forEach(({name}, k) => {
        if (k > index) {
            remove.push(name)
        }
    })

    return remove
}

export function isFloat(n) {
    return n === +n && n !== (n|0);
}

export function isInteger(n) {
    return n === +n && n === (n|0);
}

export const getLocalStorageObj = (key) => {
    return  JSON.parse(localStorage.getItem(key)) || {}
}

export const updateJsonToLocalStorage = (key, obj) => {
    const currentValues = getLocalStorageObj(key);
    localStorage.setItem(key, JSON.stringify({...currentValues, ...obj}))
}

export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
    objKeys(localStorage).forEach((key) => {
        removeFromLocalStorage(key)
    })
}

export const arraySum = (arr, field = null) => {
    if (empty(arr)) return 0

    let sum = 0;
    objVal(arr).forEach((v) => {
        sum += field ? parseFloat(v[field]) : parseFloat(v)
    });
    return sum
}

export const percentWidth = (value, total) => {
    if (!value) return 0
    return (100 / total) * value + 3
}

export const compactNumber = (number) => {
    if (number < 1000) {
        return number;
    } else if (number >= 1000 && number < 1_000_000) {
        return (number / 1000).toFixed() + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
        return float((number / 1_000_000).toFixed(1)) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
        return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
        return (number / 1_000_000_000_000).toFixed(1) + "T";
    }
}

export const percentFormat = (n) => {
    return Number(n.toString().match(/^\d+(?:\.\d{0,1})?/));
}

export const confirmAlert = (msg = null) => {
    return window.confirm(msg || "Confirm operation");
};

export const whereFields = (where, data) => {
    let found = 0;
    Object.entries(where).forEach((v, k) => {
        let keyName = v[0];
        let val = v[1];

        if (data?.[keyName] == val) {
            found++;
        }
    });

    return found === objKeys(where).length;
};

export const updateArray = (array, {where, update, qtyDelete = true}) => {
    const editedData = [];
    objVal(array).forEach((value) => {
        let newItem = {...value};

        let qtyField;
        if (inArray("quantity", objKeys(update))) {
            qtyField = "quantity";
        } else if (inArray("qty", objKeys(update))) {
            qtyField = "qty";
        }

        if (qtyDelete && whereFields(where, newItem) && qtyField && !parseInt(update?.[qtyField])) {
            newItem = {};
        }

        if (!empty(newItem)) {
            const row = whereFields(where, newItem) ? update : {};
            editedData.push({...newItem, ...row});
        }
    });
    return editedData;
};

export const isNum = (val) => !isNaN(val)

export const replaceLongName = (name) => {
    if(name.length >= 33 && name.length <= 35) {
        return '...'+name.slice(10);
    }
    if(name.length >= 36) {
        return '...'+name.slice(17).trim();
    }

    return name
}