import React from 'react';
import {MainRouter} from "./http/routes/index";
import {SnackbarProvider} from "notistack";
import {Provider as ReduxProvider} from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {store} from "./redux";
import {RealTimeProvider} from "./providers";
import { render } from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {getEnv} from "./helpers/functions";

const theme = createTheme({
    spacing: (n)=>`${n*10}px`,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 769,
            lg: 1280,
            xl: 1800,
        },
    },
    props: {
        MuiWithWidth: {
            initialWidth: 'lg',
        },
    },
});

function FallbackComponent() {
    return <div>An error has occurred</div>
}

const myFallback = <FallbackComponent />

const Root = () => {
    return (
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <RealTimeProvider>
                    <SnackbarProvider maxSnack={1}>
                        <Sentry.ErrorBoundary
                            fallback={myFallback}>
                            <MainRouter />
                        </Sentry.ErrorBoundary>
                    </SnackbarProvider>
                </RealTimeProvider>
            </ReduxProvider>
        </ThemeProvider>
    )
}

Sentry.init({
    dsn: "https://bd4a0cbb8ad94eadb76e0d5c505b57d1@o1193088.ingest.sentry.io/6315254",
    integrations: [new BrowserTracing()],
    environment: getEnv('REACT_APP_ENV'),

    beforeSend(event, hint) {

        if (getEnv('REACT_APP_ENV') == 'local') {
            return null;
        }

        const error = hint.originalException
        const msg = (error?.message || event?.message).toString()
        if (msg.indexOf('ResizeObserver') !== -1) {
            return null;
        }

        if (event.exception) {
            Sentry.showReportDialog({
                lang: 'en',
                labelName: 'Name',
                labelEmail: 'Email',
                labelComments: 'What happened?',
                labelSubmit: 'Submit'
            });
        }
        return event;
    },
    tracesSampleRate: 1.0,
});

render(<Root />, document.getElementById('root'));