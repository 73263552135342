export const fetchTreeRoute = 'dashboard/fetch-tree';
export const seenWinnerAnimationRoute = 'dashboard/seen-winner-animation';
export const fetchMainDataRoute = 'dashboard/fetch-main-data';
export const saveWindowModalApiRoute = 'dashboard/save-window-modal';

export const fetchProductsRoute = 'products/fetch-products';
export const updateQtyProdRoute = 'products/update-qty';
export const fetchTreeProdRoute = 'products/fetch-tree-products';
export const getRangeQtyRoute = 'products/get-range-qty';

export const fetchStockFiltersRoute = 'stock/fetch-filters';
export const fetchStockProductsRoute = 'stock/fetch-products';
export const updateStockPriceRoute = 'stock/update-price';

export const fetchStatusOrdersRoute = 'orders/fetch-orders';
export const fetchOrderRoute = 'orders/fetch-order';
export const fetchImeisRoute = 'orders/fetch-imei';
export const dwnImeisExcelRoute = 'orders/export-imei-excel';

export const fetchPaymentInfoRoute = 'payment-info/fetch-list';
export const fetchPaymentInfoSubscriptionOrdersRoute = 'payment-info/fetch-subscription-preorders';

export const fetchSalesStatmentRoute = 'purchase-statment/fetch-orders';
export const fetchCountdownRoute = 'dashboard/fetch-countdown';

export const fetchStatsRoute = 'statistics/fetch-stats';
export const fetchSubcatStatsCharsRoute = 'statistics/fetch-subcat-chars';
export const fetchModalStatsRoute = 'statistics/fetch-modal-stats';
export const fetchTopSuppliersRoute = 'statistics/fetch-top-suppliers';

export const fetchPackingListRoute = 'orders/fetch-packing-list';
export const exportExcelSupplierOrdersPackingListApiRoute = "orders/export-excel-packing-list";
export const fixPackingQuantityRoute = 'orders/fix-quantity';
export const fetchTurnoverProvidersApiRoute = 'orders/fetch-top-turnover';

export const fetchServiceApiRoute = 'service/fetch-service';
export const fetchServicesApiRoute = 'service/fetch-services';
export const fetchServiceFilter = 'service/fetch-filter';

export const fetchPriceListApiRoute = 'pricelist/fetch-list';
export const fetchPriceListFilterApiRoute = 'pricelist/fetch-filter';

export const fetchCurrentP2pBidsApiRoute = 'p2p/fetch-current-bids'
export const fetchCompletedP2pBidsApiRoute = 'p2p/fetch-completed-bids'
export const fetchP2pFiltersApiRoute = 'p2p/fetch-filters'
export const approveP2pBidApiRoute = 'p2p/approve'

export const profileUpdateApiRoute = 'profile/update-profile'
export const profileAvatarDeleteApiRoute = 'profile/delete-avatar'