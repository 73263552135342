import React, {useCallback, useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import axios from "axios";
import {
    _today, clearLocalStorage,
    empty,
    getEnv,
    isFnc,
    objVal,
    priceString as priceStringFnc,
    redirectToLogin
} from "./functions";
import {getRequest, postRequest} from "../services/rest";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "../redux/actions/auth";
import {useLocation, useNavigate} from "@reach/router";
import {route} from "../http/routes";
import queryString from 'query-string';
import {RealTimeContext} from "../providers/real-time-provider/context";
import * as Sentry from "@sentry/react";
import {fetchMainDataRoute, seenWinnerAnimationRoute} from "../config/api-routes";
import {
    fetchMainDataAction
} from "../redux/actions/app";
import {setPriceListFilterValueAction} from "../redux/actions/pricelist";

const useHandleChange = () => {
    const [formData, setFormData] = useState();

    const handleChange = useCallback((event) => {
        const target = event.target;
        const value  = target.type === 'checkbox' ? target.checked : target.value;
        formData[target.name] = value;
        setFormData(Object.assign({}, formData));
    }, [formData])

    return [formData, setFormData, handleChange];
}

const useFormikHandleChange = () => {
    return useCallback((e, {handleChange}) => {
        handleChange(e);
    }, [])
}

const useFormikHandleKeyUp = (timeout = 1000) => {
    let time = null;
    return useCallback((e, {handleChange, submitForm}) => {
        clearTimeout(time);
        time = setTimeout(function() {
            handleChange(e);
            submitForm();
        }, timeout);
    }, []);
}

const useNotify = () => {
    const { enqueueSnackbar } = useSnackbar();

    return (message, status = 'success', options = {}) => {
        let variant = status;
        if (typeof status == "boolean") {
            variant = status ? 'success' : 'error';
        }
        Object.assign(options, {
            variant: variant,
            autoHideDuration: 3000,
        })
        enqueueSnackbar(message, options);
    }
}

axios.defaults.baseURL = getEnv('REACT_APP_API_URL');

const useAxios = (url, params = {}, options = {}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    const fetchData = () => {

        if ('isFetch' in options && !options.isFetch) {
            return false;
        }

        getRequest(url, params)
            .then((res) => {
                setData(res);
            })
            .finally(() => {
                setloading(false);
            })
            .catch((err) => {
                setError(err);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { data, error, loading };
};

const useLogoutOnTokenExpire = () => {
    const {doLogout} = useLogout()

    useHandleChannelAction('logout', ({token}) => {
        if (localStorage.getItem('scope') === 'superadmin') {
            return;
        }

        if (token && token !== localStorage.getItem('api_token') || !token) {
            doLogout(!token)
        }
    })
}

const useLogout = () => {
    const dispatch = useDispatch();

    const appLogout = () => {
        clearLocalStorage()
        dispatch(logout());
    }

    const doLogout = (withRequest = true) => {
        if (withRequest) {
            getRequest('logout').then(() => {
                appLogout()
            }).catch(() => {
                redirectToLogin()
            })
        } else {
            appLogout()
        }
    }

    return {doLogout}
}

const use404 = () => {
    const navigate = useNavigate();
    const abort404 = () => {
        navigate(route('404'))
    }
    return abort404;
}

const useBrowseQuery = () => {
    const {search} = useLocation();

    const query = useCallback((key = false) => {
        const obj = queryString.parse(search);
        if (key) {
            return obj?.[key]
        }
        return obj;
    }, [search])

    const queryToString = (params) => {
        return '?' + queryString.stringify(params);
    }

    return {query, queryToString};
}

const useDatePickerFilter = (initialParams) => {
    const {pathname, search}     = useLocation();
    const {query, queryToString} = useBrowseQuery();
    const navigate               = useNavigate();
    const [params, setParams]    = useState(initialParams)

    useEffect(() => {
        const newParams = {};
        Object.keys(params).map((v, k) => {
            newParams[v] = query(v) ?? _today()
        })
        setParams(newParams);
    }, [search])

    const handleDateChange = (val, field) => {
        const obj = {...params, [field]: val};
        navigate(pathname + queryToString(obj));
    };

    return {handleDateChange, params};
}

const usePriceString = () => {
    const settings = useSelector((state) => state.app.settings)

    return useCallback((price, places = null) => {
        const zec = places || (settings?.price_zecimals ?? 1)
        return priceStringFnc(price, zec);
    }, [settings])
}

const useFetchAuthUser = () => {
    const dispatch   = useDispatch();
    const navigate   = useNavigate();
    const {doLogout} = useLogout();

    return (apiToken, scope) => {

        let token = apiToken || localStorage.getItem('api_token');

        postRequest('provider', {token}).then((data) => {
            if (apiToken) {
                localStorage.setItem('api_token', token)
            }

            if (scope) {
                localStorage.setItem('scope', scope)
            }

            dispatch(login(data.user));
            Sentry.setUser({...data.user});
            navigate(route('dashboard'));
        }).catch((error) => {
            doLogout();
            redirectToLogin();
        })
    }
}

const useHandleChannelAction = (action, callback) => {
    const pusher_channel_data = useSelector((state) => state.app.pusher_channel_data)

    const isChannelAction = () => {
        return pusher_channel_data.action === action;
    }

    useEffect(() => {
        if (isChannelAction()) callback(pusher_channel_data.data)
    }, [pusher_channel_data])
}

const useSeenWinnerAnimation = () => {
    return () => {
        postRequest(seenWinnerAnimationRoute, {})
    }
}

const useFetchMainData = () => {
    const dispatch = useDispatch();

    return () => {
        getRequest(fetchMainDataRoute, {}).then(({pricelist_filter, ...data}) => {
            if (!empty(pricelist_filter)) {
                dispatch(setPriceListFilterValueAction(pricelist_filter))
            }
            dispatch(fetchMainDataAction(data))
        })
    }
}

const useSubscribeAndBindChannel = (channelName, eventName, callback, deps = [], realtimePusher = null) => {
    const { pusher: reduxPusher } = useContext(RealTimeContext);

    const pusher = realtimePusher || reduxPusher;

    useEffect(() => {
        if (!pusher) return

        const channel = pusher.subscribe(channelName)
        channel.bind(eventName, function(data) {
            callback(data)
        });

        return () => {
            channel.unbind(eventName);
        }
    }, [...deps, pusher])
}

export const useFetchItem = (route, dispatchAction = false) => {
    const [item, setItem] = useState(null);
    const dispatch = useDispatch()

    const fetchItem = (params) => {
        getRequest(route, params).then((result) => {
            if (isFnc(dispatchAction)) {
                dispatch(dispatchAction(result));
            } else {
                setItem(result);
            }
        });
    };

    return {fetchItem, setItem, item};
}

export const useFetch = (route, dispatchAction = false) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const fetchRequest = (params) => {
        return new Promise((resolve) => {
            setLoading(true);
            getRequest(route, params).then((result) => {

                const typeOfKey = typeof Object.keys(result)?.[0];
                const prerparedResp = typeOfKey === "string" ? result : objVal(result);

                if (isFnc(dispatchAction)) {
                    dispatch(dispatchAction(prerparedResp));
                } else {
                    setData(prerparedResp);
                    resolve(prerparedResp);
                }
                setLoading(false);
            });
        })
    };

    return {fetchRequest, setData, data, loading};
};

export const useRequest = (route) => {
    return (data) => postRequest(route, data);
}

export {
    useHandleChange,
    useNotify,
    useAxios,
    useLogoutOnTokenExpire,
    useLogout,
    useFormikHandleKeyUp,
    useFormikHandleChange,
    use404,
    useBrowseQuery,
    useDatePickerFilter,
    usePriceString,
    useFetchAuthUser,
    useHandleChannelAction,
    useSubscribeAndBindChannel,
    useSeenWinnerAnimation,
    useFetchMainData
}