
const initialState = {
    before_state: [],
    folders: {},
    folders_products: [],
    folder_data: {
        type: null,
        params: {}
    },
    active_folder: null
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'SET_FOLDER_DATA':
            return {
                ...state,
                folder_data: payload.folder_data,
                active_folder: payload.active_folder
            }

        case 'RESET_FOLDER_DATA':
            return {
                ...state,
                folder_data: {
                    type: null,
                    params: {}
                },
                active_folder: null
            }

        case 'SET_PRODUCTS_FOLDER':
            return {
                ...state,
                folders_products: payload
            }

        case 'FETCH_FOLDERS':
            return {
                ...state,
                folders: {...state.folders, ...payload}
            }

        case 'SET_ACTIVE_FOLDER':
            return {
                ...state,
                active_folder: payload
            }

        case 'SET_BEFORE_STATE_DATA_ACTION':
            return {
                ...state,
                before_state: payload
            }

        default:
            return state;
    }
}

const tree = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    return {
        ...mainStates,
    }
}

export default tree;