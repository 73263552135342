import {objVal, sortBy} from "../../helpers/functions";

const windowModalDefProps = {
    collapse: false,
    open: false,
    width:670,
    height: 510,
    x:0,
    y: 0,
    zIndex: 1
}

const initialState = {
    countdown: [],
    constants: null,
    settings: null,
    pusher_channel_data: {
        action: null,
        data: []
    },
    auth: {
        user: {},
        isAuth: null
    },
    ballance_info: [],
    penalty_info: [],
    modal_width: 'auto',
    open_sidebar: false,
    open_filter_slidebar: false,
    show_filter_btn: false,
    top_turnover: {
        current: {
            totals: [],
            max: 0,
            bonus_prize: 0,
            date_from: null
        },

        prev: {
            max: 0,
            bonus_prize: 0,
            self_turnover: 0,
            date_from: null,
            date_to: null,
            debit: null
        }
    },

    competiton_options: {
        max_provider_turnover: 0,
        bonus_prize: 0,
        is_enable_chart: null,
        is_enable_animation: null,
        is_enable_ballance_animation: null,
        is_winner: null,
        ballance_animation_showed: false,
        diagram_animation_showed: false
    },

    window_modal: {
        pricelist: {
            name: 'Price List',
            ...windowModalDefProps
        },
        statistics: {
            name: 'Statistics',
            ...windowModalDefProps
        }
    }
}

const setPusherChannelData = (payload) => {
    return {
        ...payload
    }
}

const updateTopProvider = (top_turnover, {id_provider, total}) => {
    const orderIndex = objVal(top_turnover.current.totals).findIndex((v) => v.id_user === id_provider);
    const top = {id_user: id_provider, total}

    let totals = top_turnover.current.totals;
    if (orderIndex === -1) {
        totals.push({...top});
    } else {
        totals = [...totals.slice(0, orderIndex), top, ...totals.slice(orderIndex + 1)];
    }

    return {
        current: {
            ...top_turnover.current,
            totals: sortBy(totals, "total", 'desc')
        },
        prev: top_turnover.prev
    }
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_SETTINGS':
            return {
                ...state,
                settings: payload
            }

        case 'FETCH_MAIN_DATA':

            return {
                ...state,
                ...payload,
                window_modal: {
                    ...state.window_modal,
                    ...payload?.window_modal || {}
                },
                competiton_options: {
                    ...state.competiton_options,
                    ...(payload.competiton_options ? payload.competiton_options : {})
                }
            }

        case 'UPDATE_COMPETITION_OPTIONS':
            return {
                ...state,
                competiton_options: {
                    ...state.competiton_options,
                    ...payload
                }
            }

        case 'FETCH_COUNTDOWN':
            return {
                ...state,
                countdown: payload,
                // pusher_channel_data: {
                //     action: 'switch-stock',
                //     data: payload
                // },
            }

        case 'SET_OPEN_SIDEBAR':
            return {
                ...state,
                open_sidebar: payload
            }

        case 'FETCH_BALLANCE_INFO':
            return {
                ...state,
                ballance_info: payload
            }

        case 'SET_PENALTY_INFO':
            return {
                ...state,
                penalty_info: payload
            }

        case 'SET_PUSHER_CHANNEL_DATA':
            return {
                ...state,
                pusher_channel_data: setPusherChannelData(payload)
            }

        case 'FETCH_CONST':
            return {
                ...state,
                constants: payload
            }

        case 'SET_MODAL_WIDTH':
            return {
                ...state,
                modal_width: payload
            }

        case 'SET_OPEN_FILTER_SLIDEBAR':
            return {
                ...state,
                open_filter_slidebar: payload
            }

            // pricelist: {
            //     size: {width:0, height: 0},
            //     position: {x:0, y: 0}
            // }

        case 'SET_WINDOW_MODAL_OPTIONS':
            const {modal, ...props} = payload

            return {
                ...state,
                window_modal: {
                    ...state.window_modal,
                    [modal]: {
                        ...state.window_modal[modal],
                        ...props
                    }
                }
            }

        case 'SHOW_FILTER_BTN':
            return {
                ...state,
                show_filter_btn: payload
            }

        case 'RESET_SLIDER_FILTER':
            return {
                ...state,
                show_filter_btn: false,
                open_filter_slidebar: false
            }

        case "FETCH_TOP_PROVIDER":

            const {user} = state.auth

            let current = null
            if (payload.current !== null) {
                let totals = payload.current.totals;
                if (totals.findIndex(({id_user}) => id_user === user.id_provider) === -1) {
                    totals.push({
                        id_user: user.id_provider,
                        total: 0
                    })
                }

                current = {
                    ...payload.current,
                    totals
                }
            }

            return {
                ...state,
                top_turnover: {
                    ...state.top_turnover,
                    ...(current ? {current} : {}),
                    ...(payload.prev ? {prev: payload.prev} : {})
                },
            };

        case "RESET_TOP_PROVIDER":
            return {
                ...state,
                top_turnover: initialState.top_turnover,
            }

        case "TOP_PROVIDER_REALTIME":
            return {
                ...state,
                top_turnover: updateTopProvider(state.top_turnover, payload),
            };

        default:
            return state;
    }
}

const updateAuthStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'FETCH_USER':
            const status = Object.keys(payload).length;
            return {
                isAuth: status ? true : false,
                user: payload
            }

        case 'LOGOUT':
            return {
                isAuth: false,
                user: {}
            }

        case 'LOGIN':
            return {
                isAuth: true,
                user: payload
            }

        case 'UPDATE_USER_DATA':
            return {
                ...state.auth,
                user: {
                    ...state.auth.user,
                    ...payload
                }
            }

        case 'UPDATE_USER_DATA_AVATAR':
            return {
                user: {
                    avatar: payload
                }
            }

        default:
            return state.auth;
    }
}

const app = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    const authStates = updateAuthStates(state, action);

    return {
        ...mainStates,
        auth: authStates
    }
}

export default app;