export const fetchConstants = (payload) => {
    return {
        type: 'FETCH_CONST',
        payload
    }
}

export const fetchSettings = (payload) => {
    return {
        type: 'FETCH_SETTINGS',
        payload
    }
}

export const setPusherChannelDataAction = (payload) => {
    return {
        type: 'SET_PUSHER_CHANNEL_DATA',
        payload
    }
}

export const setModalWidthAction = (payload) => {
    return {
        type: 'SET_MODAL_WIDTH',
        payload
    }
}

export const setCountdownAction = (payload) => {
    return {
        type: 'FETCH_COUNTDOWN',
        payload
    }
}

export const setOpenSidebarAction = (payload) => {
    return {
        type: 'SET_OPEN_SIDEBAR',
        payload
    }
}

export const setOpenFilterSlidebarAction = (payload) => {
    return {
        type: 'SET_OPEN_FILTER_SLIDEBAR',
        payload
    }
}

export const showFilterBtnAction = (payload) => {
    return {
        type: 'SHOW_FILTER_BTN',
        payload
    }
}

export const resetSliderFilterAction = () => {
    return {
        type: 'RESET_SLIDER_FILTER'
    }
}

export const fetchTopProviderAction = (payload) => {
    return {
        type: "FETCH_TOP_PROVIDER",
        payload,
    };
};

export const resetTopProviderAction = () => {
    return {
        type: "RESET_TOP_PROVIDER"
    };
};

export const topProviderRealtimeAction = (payload) => {
    return {
        type: "TOP_PROVIDER_REALTIME",
        payload,
    };
};

export const fetchMainDataAction = (payload) => {
    return {
        type: "FETCH_MAIN_DATA",
        payload,
    };
};

export const updateCompetitionOptionsAction = (payload) => {
    return {
        type: "UPDATE_COMPETITION_OPTIONS",
        payload,
    };
};

export const setWindowModalOptionsAction = (payload) => {
    return {
        type: "SET_WINDOW_MODAL_OPTIONS",
        payload,
    };
};