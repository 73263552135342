import {orderBy} from "lodash/collection";

const initialState = {
    products: [],
    load: false,
    can_edit: false,
    qty_ranges: [],
    models: [],
    select_filter: false,
    filter_params: {
        qty_ranges: [],
        models: [],
        brand: []
    }
}

const deleteProduct = (products, payload) => {
    const filteredData = products.filter((val, k) => {
        return parseInt(val.id_product) !== parseInt(payload)
    })

    return [...filteredData];
}

const updateProduct = (products, {id_product, items}) => {
    let update = false;
    const keys = [];
    products.map((v, key) => {
        if (parseInt(v.id_product) === parseInt(id_product)) {
            update = true;
            keys.push(key);
        }
    });

    const min = Math.min(...keys);
    const max = Math.max(...keys);

    if (update) {
        return [...products.slice(0, min), ...items, ...products.slice(max+1)]
    }

    const index = orderBy(products, 'page_up', 'asc').findIndex((v) => v.page_up > items[0].page_up)
    return orderBy([...products.slice(0, index), ...items, ...products.slice(index)], 'page_up', 'asc')
}

const updateModels = (models, {info}) => {
    const splitInfo      = info.split('|');
    const id_subcategory = parseInt(splitInfo[1])
    const subcat_name    = splitInfo[5];
    const subcat_page_up = parseInt(splitInfo[6]);
    const cat_page_up    = parseInt(splitInfo[7]);

    const id_brand      = parseInt(splitInfo[8])
    const brand_name    = splitInfo[9];
    const brand_page_ap = parseInt(splitInfo[10]);

    if (models.findIndex((v) => v.id_subcategory === id_subcategory && v.id_brand === id_brand) === -1) {
        return orderBy([...models, {
            brand_name,
            brand_page_ap,
            subcat_page_up,
            cat_page_up,
            id_brand,
            id_subcategory,
            subcat_name,
        }], ['brand_page_ap', 'cat_page_up', 'subcat_page_up'], ['asc', 'asc', 'asc']);
    }
    return models
}

const updateMainStates = (state = initialState, {type, payload}) => {
    switch (type) {
        case 'LOADING':
            return {
                ...state,
                load: true
            }

        case 'FETCH_PRODUCTS':
            return {
                ...state,
                load: false,
                products: payload
            }

        case 'DELETE_STOCK_PRODUCT':
            return {
                ...state,
                products: deleteProduct(state.products, payload)
            }

        case 'UPDATE_STOCK_PRODUCT':
            return {
                ...state,
                products: updateProduct(state.products, payload)
            }

        case 'SET_EDIT_PRIV':
            return {
                ...state,
                can_edit: payload
            }

        case 'FETCH_QTY_RANGES':
            return {
                ...state,
                qty_ranges: payload
            }

        case 'FETCH_MODELS':
            return {
                ...state,
                models: payload
            }

        case 'FETCH_FILTER_DATA':
            return {
                ...state,
                models: payload.models,
                qty_ranges: payload.qty_ranges
            }

        case 'UPDATE_STOCK_FILTER_MODELS':
            return {
                ...state,
                models: updateModels(state.models, payload)
            }

        case 'FETCH_FILTER_PARAMS':
            return {
                ...state,
                filter_params: payload
            }

        case 'SET_SELECT_FILTER':
            return {
                ...state,
                select_filter: payload
            }

        case 'RESET_STOCK_VARS':
            return {
                ...state,
                qty_ranges: [],
                load: false,
                products: [],
                can_edit: false,
                filter_params: {},
                models: []
            }

        default:
            return state;
    }
}

const stock = (state = initialState, action) => {
    const mainStates = updateMainStates(state, action);
    return {
        ...mainStates,
    }
}

export default stock