import React, {Fragment, memo} from "react";
import {Redirect, Router} from "@reach/router";
import {RouteAgent, route, setRoutes} from "./core"
import NotFound from "../../http/pages/not_found";
import Loadable from "react-loadable";
import guest from "../../http/middleware/guest";
import auth from "../middleware/auth";
import {PageLoader} from "../../components/loadings";
import {useSelector} from "react-redux";

const load = (importComponent) => Loadable({
    loader: () => new Promise((resolve) => {
        resolve(importComponent())
    }),
    loading: PageLoader
});

RouteAgent.group({middleware: [guest]}, () => {
    RouteAgent.group({prefix: 'oauth'}, () => {
        RouteAgent.path('authorize')
            .page(load(() => import('../../http/pages/authorize')))
            .name('login')
            .push();
    });
}).break();

RouteAgent.group({middleware: [auth]}, () => {
    RouteAgent.path('dashboard')
        .page(load(() => import('../../http/pages/dashboard')))
        .name('dashboard')
        .push();
}).break();

RouteAgent.path('page-404')
    .page(load(() => import('../../http/pages/not_found')))
    .name('404')
    .push();

const Home =() => {

    const {isAuth} = useSelector((state) => state.app.auth);

    if (isAuth) {
        return <Redirect noThrow to={route('dashboard')} />
    }

    return <Redirect noThrow to={route('login')} />
}

function MainRouter(props) {
    return (
        <Fragment>
            {props.children}
            <Router>
                <Home path="/"/>
                {setRoutes()}
                <NotFound default/>
            </Router>
        </Fragment>
    )
}

export {MainRouter, route}
